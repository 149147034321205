import { Col, Row } from "react-bootstrap";
import StepOne from "./assets/step1.png";
import StepTwo from "./assets/step2.png";
import StepThree from "./assets/step3.png";
import styles from "./registration-steps.module.scss";

const RegistrationSteps = () => {
  return (
    <>
      <Row>
        <div
          className={`${styles.registrationStepsHeading} text-center text-md-end`}
        >
          Register Now with My LECO app
        </div>
      </Row>
      <Row>
        <div
          className={`${styles.registrationStepsSubHeading} text-center text-md-end`}
        >
          Welcome to oversight, Please enter your details
        </div>
      </Row>
      <Row className="mt-5 justify-content-center justify-content-md-end">
        <Col className="col-4">
          <img
            src={StepOne}
            className="img-fluid"
            alt="registration step one"
          />
          <div className={`${styles.stepHeading} mt-4`}>Step 1</div>
          <p className={`${styles.step}`}>Install My LECO app on your device</p>
        </Col>
        <Col className="col-4">
          <img
            src={StepTwo}
            className="img-fluid"
            alt="registration step two"
          />
          <div className={`${styles.stepHeading} mt-4`}>Step 2</div>
          <p className={`${styles.step}`}>Create account in the My LECO app</p>
        </Col>
        <Col className="col-4">
          <img
            src={StepThree}
            className="img-fluid"
            alt="registration step three"
          />
          <div className={`${styles.stepHeading} mt-4`}>Step 3</div>
          <p className={`${styles.step}`}>
            Use those username & Password to login to oversight
          </p>
        </Col>
      </Row>
    </>
  );
};

export default RegistrationSteps;
