import moment from "moment";
import Tooltip from "rc-tooltip";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import ReactGA from "react-ga4";
import { useForm } from "react-hook-form";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import {
  useAddManualScheduleMutation,
  useAddSemiAutomationScheduleMutation,
  useLazyViewManualScheduleAvailabilityQuery,
  useLazyViewManualScheduledSpaceClusterQuery,
  useLazyViewSemiAutomationScheduleAvailabilityQuery,
  useLazyViewSemiAutomationScheduledSpaceClusterQuery,
  useLazyViewSubSpaceClusterWithManualScheduleAvailabilityQuery,
  useLazyViewSubSpaceClusterWithSemiAutomationScheduleAvailabilityQuery,
  useUpdateManualScheduleMutation,
  useUpdateSemiAutomationScheduleMutation,
} from "../../../../../redux/api/schedule/scheduleAPI";
import { useLazyGetSpaceHierarchyQuery } from "../../../../../redux/api/space/spaceAPI";
import {
  ITimeRangeProps,
  initialTime,
} from "../../../../../shared/components/add-time-range/add-time-range";
import AddManualScheduleRequestDTO from "../../../../../shared/oversight-core/dtos/request-dtos/add-manual-schedule-request-dto";
import AddSemiAutomatedScheduledRequestDTO from "../../../../../shared/oversight-core/dtos/request-dtos/add-semi-automated-scheduled-request-dto";
import ViewSemiAutomationScheduleDeviceAvailabilityRequestDTO from "../../../../../shared/oversight-core/dtos/request-dtos/view-semi-automation-schedule-device-availability-request-dto";
import ViewSubSpaceClustersWithScheduleAvailabilityRequestDTO from "../../../../../shared/oversight-core/dtos/request-dtos/view-sub-space-clusters-with-schedule-availability-request-dto";
import ViewManualScheduleResponseDTO from "../../../../../shared/oversight-core/dtos/response-dtos/view-manual-schedule-response-dto";
import ViewSemiAutomationScheduleDeviceAvailabilityResponseDTO, {
  IPowerConsumerTypeAvailabilityViews,
} from "../../../../../shared/oversight-core/dtos/response-dtos/view-semi-automation-schedule-device-availability-response-dto";
import ViewSemiAutomationScheduleResponseDTO from "../../../../../shared/oversight-core/dtos/response-dtos/view-semi-automation-schedule-response-dto";
import { EDeviceTypes } from "../../../../../shared/oversight-core/enums/device-types";
import { ELimitationType } from "../../../../../shared/oversight-core/enums/limitation-type";
import { EManagementMode } from "../../../../../shared/oversight-core/enums/management-mode";
import { OvstErrorCode } from "../../../../../shared/oversight-core/enums/ovst-error-codes";
import { ERepetitionMode } from "../../../../../shared/oversight-core/enums/repetition-mode";
import { EScheduleAvailabilityType } from "../../../../../shared/oversight-core/enums/schedule-availability-type";
import { EScheduleTagType } from "../../../../../shared/oversight-core/enums/schedule-tag-type";
import { AppRoute } from "../../../../../shared/oversight-core/interfaces/app-routes";
import { IDevicesCount } from "../../../../../shared/oversight-core/interfaces/device-count";
import { IScheduledSpaceView } from "../../../../../shared/oversight-core/interfaces/scheduled-space-view";
import { ISchedulingPeriod } from "../../../../../shared/oversight-core/interfaces/scheduling-period";
import AppSelect, {
  Option,
} from "../../../../../shared/oversight-core/ui-elements/app-select/app-select";
import AppSwitch from "../../../../../shared/oversight-core/ui-elements/app-switch/app-switch";
import AppButton from "../../../../../shared/oversight-core/ui-elements/buttons/app-button/app-button";
import MaterialIcon from "../../../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import SpacePathView from "../../../../../shared/oversight-core/ui-elements/space-path-view/space-path-view";
import SpinnerModal from "../../../../../shared/oversight-core/ui-elements/spinner/spinner";
import { convertIsoStringToTimeList } from "../../../../../shared/oversight-core/utils/convert-ISO-string-to-time-list";
import { formatDate } from "../../../../../shared/oversight-core/utils/date-utils";
import { findRepetition } from "../../../../../shared/oversight-core/utils/find-repetition";
import { generateRepetitionOptions } from "../../../../../shared/oversight-core/utils/generate-repetition-options";
import getDeviceTypes, {
  getDeviceLabel,
} from "../../../../../shared/oversight-core/utils/getDeviceType";
import { formatTimeSlots } from "../../../../../shared/oversight-core/utils/time-utils";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../../../shared/oversight-core/utils/toast";
import {
  findSpace,
  findTotalCountAndAlreadyScheduledDevices,
  updateTopToBottomHelper,
} from "../../../../../shared/utils/schedule-utils";
import ScheduleConfirmationModal from "../modals/schedule-confirmation-modal/schedule-confirmation-modal";
import ScheduleDeleteModal from "../modals/schedule-delete-modal/schedule-delete-modal";
import styles from "./add-update-schedule.module.scss";
import ScheduleInformation from "./schedule-information/schedule-information";
import SpaceHierarchy, {
  IPowerConsumerViewWithSpaceIdAndClusterId,
  IScheduledSpaceViewWithDeviceStatus,
} from "./space-hierarchy/space-hierarchy";

export interface IAddScheduleProps {
  navigateLocation?: Parameters<NavigateFunction>;
  initialIsoTimeList?: Array<{ fromDate: string; toDate: string }>;
  spaceClusterId: string;
  subRootSpaceId: string;
  scheduleId?: string; // undefined if add, otherwise edit
  initialDeviceIds?: string[]; // undefined if add, otherwise edit
  managementMode: EManagementMode;
}

const defaultDevicesCount: IDevicesCount = {
  selected: 0,
  total: 0,
  excluded: 0,
};

const deviceTypes: Option[] = [
  { value: "", label: getDeviceLabel(EDeviceTypes.ALL_DEVICES, true) },
  ...getDeviceTypes(true),
];

const defaultDeviceType: Option = deviceTypes[0];

interface IFormInput
  extends Omit<
    AddSemiAutomatedScheduledRequestDTO | AddManualScheduleRequestDTO,
    "dateTimeSlots"
  > {
  schedulingPeriods: ISchedulingPeriod[];
}

const defaultFormValues: IFormInput = {
  title: "",
  schedulingDate: "",
  expireDate: "",
  schedulingPeriods: [],
  repetitionMode: ERepetitionMode.DO_NOT_REPEAT,
  selectedPowerConsumerIdentities: [],
  isDraft: true,
};

const AddUpdateSchedule = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const locationState: IAddScheduleProps = location.state as IAddScheduleProps;
  const isSemiAutoMode =
    locationState?.managementMode === EManagementMode.SEMI_AUTO;

  const initialDate = locationState.initialIsoTimeList?.[0].fromDate
    ? new Date(locationState.initialIsoTimeList[0].fromDate)
    : new Date();
  const initialTimeList = locationState.initialIsoTimeList
    ? convertIsoStringToTimeList(locationState.initialIsoTimeList)
    : [{ ...initialTime }];

  const [spaceClusters, setSpaceClusters] = useState<
    IScheduledSpaceViewWithDeviceStatus[]
  >([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  const [selectedDate, setSelectedDate] = useState(initialDate);
  const [selectedEndDate, setSelectedEndDate] = useState(initialDate);
  const [devicesCount, setDevicesCount] = useState<IDevicesCount>({
    ...defaultDevicesCount,
  });
  const [alreadyScheduleDevices, setAlreadyScheduleDevices] = useState<
    IPowerConsumerViewWithSpaceIdAndClusterId[]
  >([]);
  const [timeList, setTimeList] = useState<ITimeRangeProps[]>(initialTimeList);
  const [selectedPowerConsumerList, setSelectedPowerConsumerLists] = useState<
    string[]
  >([]);
  const [isApplyForChildSpaces, setIsApplyForChildSpaces] = useState(false);
  const [availabilityPowerConsumerList, setAvailabilityPowerConsumerList] =
    useState<{ deviceId: string; dateTimeRange: ISchedulingPeriod[] }[]>([]);
  const [selectedDeviceType, setSelectedDeviceType] = useState<Option>({
    ...defaultDeviceType,
  });
  const [isOnDraftLoading, setIsOnDraftLoading] = useState(false);
  const [isOnAutomateLoading, setIsOnAutomateLoading] = useState(false);
  const [isAutomate, setIsAutomate] = useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const [formData, setFormData] = useState({ ...defaultFormValues });
  const [isTimeListHasErrors, setIsTimeListHasErrors] = useState(false);
  const [collapsed, setCollapsed] = useState(true);
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [repetitionOptions, setRepetitionOptions] = useState<Option[]>(() =>
    generateRepetitionOptions(selectedDate)
  );
  const [selectedRepetition, setSelectedRepetition] = useState<Option>(
    repetitionOptions[0]
  );
  const [repetitionMode, setRepetitionMode] = useState<ERepetitionMode>(
    ERepetitionMode.DO_NOT_REPEAT
  );
  const [breadCrumbPath, setBreadCrumbPath] = useState("");
  const [powerConsumerUsageGuide, setPowerConsumerUsageGuide] = useState<
    IPowerConsumerTypeAvailabilityViews[]
  >([]);
  const [endDateError, setEndDateError] = useState("");

  const [
    addSemiAutomationSchedule,
    { isSuccess: isSuccessAddSemiAutomationSchedule },
  ] = useAddSemiAutomationScheduleMutation();

  const [addManualSchedule, { isSuccess: isSuccessAddManualSchedule }] =
    useAddManualScheduleMutation();

  const [
    updateSemiAutomationSchedule,
    { isSuccess: isSuccessUpdateSemiAutomationSchedule },
  ] = useUpdateSemiAutomationScheduleMutation();

  const [updateManualSchedule, { isSuccess: isSuccessUpdateManualSchedule }] =
    useUpdateManualScheduleMutation();

  const [
    triggerGetSubSpaceClusterWithSemiAutomationScheduleAvailability,
    {
      data: getDataSubSpaceClusterWithSemiAutomationScheduleAvailability,
      isSuccess: isSuccessSubSpaceClusterWithSemiAutomationScheduleAvailability,
      isFetching:
        isFetchingSubSpaceClusterWithSemiAutomationScheduleAvailability,
    },
  ] = useLazyViewSubSpaceClusterWithSemiAutomationScheduleAvailabilityQuery();

  const [
    triggerGetSubSpaceClusterWithManualScheduleAvailability,
    {
      data: getDataSubSpaceClusterWithManualScheduleAvailability,
      isSuccess: isSuccessSubSpaceClusterWithManualScheduleAvailability,
      isFetching: isFetchingSubSpaceClusterWithManualScheduleAvailability,
    },
  ] = useLazyViewSubSpaceClusterWithManualScheduleAvailabilityQuery();

  const [
    triggerGetSemiAutomationScheduledSpaceCluster,
    {
      data: getDataSemiAutomatedScheduledSpaceCluster,
      isSuccess: isSuccessViewSemiAutomatedScheduledSpaceCluster,
      isFetching: isFetchingViewSemiAutomatedScheduledSpaceCluster,
    },
  ] = useLazyViewSemiAutomationScheduledSpaceClusterQuery();

  const [
    triggerGetManualScheduledSpaceCluster,
    {
      data: getDataManualScheduledSpaceCluster,
      isSuccess: isSuccessViewManualScheduledSpaceCluster,
      isFetching: isFetchingViewManualScheduledSpaceCluster,
    },
  ] = useLazyViewManualScheduledSpaceClusterQuery();

  const [
    triggerGetSemiAutomationScheduleAvailability,
    { isFetching: isFetchingSemiAutomationScheduleAvailability },
  ] = useLazyViewSemiAutomationScheduleAvailabilityQuery();

  const [
    triggerGetManualScheduleAvailability,
    { isFetching: isFetchingManualScheduleAvailability },
  ] = useLazyViewManualScheduleAvailabilityQuery();

  const [triggerGetHierarchy] = useLazyGetSpaceHierarchyQuery();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
    watch,
  } = useForm<IFormInput>({ defaultValues: { ...defaultFormValues } });

  useEffect(() => {
    setRepetitionOptions(generateRepetitionOptions(selectedDate));
    setSelectedRepetition(
      findRepetition(repetitionMode, generateRepetitionOptions(selectedDate))
    );
  }, [selectedDate, repetitionMode]);

  useEffect(() => {
    setRepetitionMode(selectedRepetition.value as ERepetitionMode);
  }, [selectedRepetition]);

  useEffect(() => {
    if (locationState?.scheduleId) {
      reset({ ...defaultFormValues });
      setTimeList([{ ...initialTime }]);

      if (isSemiAutoMode) {
        triggerGetSemiAutomationScheduledSpaceCluster({
          spaceClusterId: locationState.spaceClusterId,
          subRootSpaceId: locationState.subRootSpaceId,
          scheduleId: locationState.scheduleId,
        })
          .unwrap()
          .then((res: ViewSemiAutomationScheduleResponseDTO) => {
            setValue("title", res.semiAutomatedScheduleView.title);
            setValue("schedulingDate", defaultFormValues.schedulingDate);
            setValue(
              "selectedPowerConsumerIdentities",
              defaultFormValues.selectedPowerConsumerIdentities
            );
            setValue("isDraft", res.semiAutomatedScheduleView.isDraft);
            setSelectedDate(
              new Date(
                res.semiAutomatedScheduleView.scheduledPeriods[0].fromDate
              )
            );
            setSelectedEndDate(
              moment(
                res.semiAutomatedScheduleView.expireDate ||
                  res.semiAutomatedScheduleView.scheduledPeriods[0].fromDate
              ).toDate()
            );

            setTimeList(
              convertIsoStringToTimeList(
                res.semiAutomatedScheduleView.scheduledPeriods
              )
            );
            setRepetitionMode(
              res.semiAutomatedScheduleView.repetitionMode as ERepetitionMode
            );
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        triggerGetManualScheduledSpaceCluster({
          spaceClusterId: locationState.spaceClusterId,
          subRootSpaceId: locationState.subRootSpaceId,
          scheduleId: locationState.scheduleId,
        })
          .unwrap()
          .then((res: ViewManualScheduleResponseDTO) => {
            setValue("title", res.scheduleView.title);
            setValue("schedulingDate", defaultFormValues.schedulingDate);
            setValue(
              "selectedPowerConsumerIdentities",
              defaultFormValues.selectedPowerConsumerIdentities
            );
            setValue("isDraft", res.scheduleView.isDraft);
            setSelectedDate(
              new Date(res.scheduleView.scheduledPeriods[0].fromDate)
            );
            setSelectedEndDate(
              moment(
                res.scheduleView.expireDate ||
                  res.scheduleView.scheduledPeriods[0].fromDate
              ).toDate()
            );

            setTimeList(
              convertIsoStringToTimeList(res.scheduleView.scheduledPeriods)
            );
            setRepetitionMode(
              res.scheduleView.repetitionMode as ERepetitionMode
            );
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  }, [
    triggerGetSemiAutomationScheduledSpaceCluster,
    triggerGetManualScheduledSpaceCluster,
  ]);

  useEffect(() => {
    if (!isApplyForChildSpaces && spaceClusters.length > 0) {
      setSpaceClusters((ps) => {
        const tempArray = [...ps];

        let isAllDevicesActivated = true;

        for (const pc of tempArray[0].powerConsumers) {
          if (!pc.isSelected) {
            isAllDevicesActivated = false;
            break;
          }
        }

        tempArray[0].isSelected =
          isAllDevicesActivated && tempArray[0].powerConsumers.length > 0;

        return tempArray;
      });
    }
  }, [isApplyForChildSpaces]);

  useEffect(() => {
    if (spaceClusters.length > 0) {
      if (!isApplyForChildSpaces) {
        let isSelectedCount = 0;
        let excludedCount = 0;

        for (const powerConsumer of spaceClusters[0].powerConsumers) {
          if (powerConsumer.isSelected) {
            ++isSelectedCount;
          }
          if (powerConsumer.isExcluded) {
            ++excludedCount;
          }
        }

        setDevicesCount({
          selected: isSelectedCount,
          excluded: excludedCount,
          total: spaceClusters[0].powerConsumers.length,
        });

        setAlreadyScheduleDevices(
          spaceClusters[0].powerConsumers.filter(
            (powerConsumer) =>
              powerConsumer.isScheduled && powerConsumer.isSelected
          )
        );

        setSelectedPowerConsumerLists(
          spaceClusters[0].powerConsumers
            .filter((powerConsumer) => powerConsumer.isSelected)
            .map((pc) => pc.id)
        );
      } else {
        const {
          selected,
          total,
          excluded,
          alreadyScheduledDevices,
          selectedDevices,
        } = findTotalCountAndAlreadyScheduledDevices(spaceClusters[0]);
        setDevicesCount({ selected, total, excluded });
        setAlreadyScheduleDevices([...alreadyScheduledDevices]);
        setSelectedPowerConsumerLists(selectedDevices);
      }
    }
  }, [spaceClusters, isApplyForChildSpaces]);

  const onSubmit = async (data: IFormInput, isDraft: boolean) => {
    const scheduleId = locationState.scheduleId;
    const newSchedule = {
      spaceClusterId: locationState.spaceClusterId,
      subRootSpaceId: locationState.subRootSpaceId,
      title: data.title,
      schedulingDate: formatDate(selectedDate),
      expireDate:
        selectedRepetition.value === ERepetitionMode.DO_NOT_REPEAT
          ? null
          : formatDate(moment(selectedEndDate).startOf("day").toDate()),
      schedulingPeriods: formatTimeSlots(selectedDate, timeList),
      repetitionMode: selectedRepetition?.value as ERepetitionMode,
      selectedPowerConsumerIdentities: selectedPowerConsumerList,
      isDraft: isDraft,
      scheduleTag: EScheduleTagType.SCHEDULE,
    };

    if (!scheduleId) {
      if (isSemiAutoMode) {
        await addSemiAutomationSchedule(newSchedule)
          .unwrap()
          .then(() => {
            ReactGA.event({
              category: "Successful",
              action: "Semi Auto Schedule Add",
            });
          })
          .catch((error) => {
            if (
              error.status === 412 &&
              error.ovstErrorCode === OvstErrorCode.OVST_CONS_0001
            ) {
              showErrorMessage("Schedule title already in use");
            } else
              showErrorMessage("Adding Semi-Automation Schedule Unsuccessful");
          });
      } else {
        await addManualSchedule(newSchedule)
          .unwrap()
          .catch((error) => {
            if (
              error.status === 412 &&
              error.ovstErrorCode === OvstErrorCode.OVST_CONS_0001
            ) {
              showErrorMessage("Schedule title already in use");
            } else showErrorMessage("Adding Manual Schedule Unsuccessful");
          });
      }
    } else {
      if (isSemiAutoMode) {
        await updateSemiAutomationSchedule({
          ...newSchedule,
          semiAutomatedScheduleId: scheduleId,
        })
          .unwrap()
          .then(() => {
            ReactGA.event({
              category: "Successful",
              action: "Semi Auto Schedule Edit",
            });
          })
          .catch(() => {
            showErrorMessage("Updating Semi-Automation Schedule Unsuccessful");
          });
      } else {
        await updateManualSchedule({
          ...newSchedule,
          manualScheduleId: scheduleId,
        })
          .unwrap()
          .catch(() => {
            showErrorMessage("Updating Manual Schedule Unsuccessful");
          });
      }
    }
  };

  const onScheduleSubmit = async (data: IFormInput, isAutomate: boolean) => {
    const setLoading = isAutomate
      ? setIsOnAutomateLoading
      : setIsOnDraftLoading;
    const submitFunction = isAutomate ? setIsAutomate(true) : setIsDraft(true);

    setFormData(data);

    if (
      selectedEndDate <= selectedDate &&
      !(selectedRepetition.value === ERepetitionMode.DO_NOT_REPEAT)
    ) {
      setEndDateError("Expire date should be later than start date");
      return;
    }

    setEndDateError("");

    if (selectedPowerConsumerList.length < 1) {
      showErrorMessage(
        "Please select at least one device in order to create a schedule"
      );
      return;
    }

    if (
      spaceClusters[0] &&
      spaceClusters[0].powerConsumers.filter((pc) => pc.isSelected).length < 1
    ) {
      showErrorMessage(
        "Please select at least one device from the parent space in order to create a schedule"
      );
      return;
    }

    if (isTimeListHasErrors) {
      return;
    }

    if (alreadyScheduleDevices.length === 0) {
      setLoading(true);
      await onSubmit(data, !isAutomate);
      setLoading(false);
      submitFunction;
    }
  };

  const onAutomateSubmit = async (data: IFormInput) => {
    setIsDraft(false);
    await onScheduleSubmit(data, true);
  };

  const onDraftSubmit = async (data: IFormInput) => {
    setIsAutomate(false);
    await onScheduleSubmit(data, false);
  };

  useEffect(() => {
    if (
      isSuccessAddSemiAutomationSchedule ||
      isSuccessUpdateSemiAutomationSchedule ||
      isSuccessAddManualSchedule ||
      isSuccessUpdateManualSchedule
    ) {
      const messageOfSemiAutomation = isSuccessAddSemiAutomationSchedule
        ? isAutomate && !isDraft
          ? "Semi-Automation Schedule Saved Successfully"
          : "Semi-Automation Schedule Saved as Draft Successfully"
        : isAutomate && !isDraft
        ? "Semi-Automation Schedule Updated Successfully"
        : "Semi-Automation Schedule Updated as Draft Successfully";

      const messageOfManual = isSuccessAddManualSchedule
        ? isAutomate && !isDraft
          ? "Manual Schedule Saved Successfully"
          : "Manual Schedule Saved as Draft Successfully"
        : isAutomate && !isDraft
        ? "Manual Schedule Updated Successfully"
        : "Manual Schedule Updated as Draft Successfully";

      showSuccessMessage(
        isSemiAutoMode ? messageOfSemiAutomation : messageOfManual
      );
      reset({ ...defaultFormValues });
      if (locationState.navigateLocation) {
        navigate(...locationState.navigateLocation);
      }
    }
  }, [
    isSuccessAddSemiAutomationSchedule,
    isSuccessUpdateSemiAutomationSchedule,
    isSuccessAddManualSchedule,
    isSuccessUpdateManualSchedule,
  ]);

  useEffect(() => {
    let tempTimeList: {
      fromDate: string;
      toDate: string;
    }[] = [];

    if (
      timeList[0] &&
      timeList[0].startTime.value &&
      timeList[timeList.length - 1] &&
      timeList[timeList.length - 1].endTime.value
    ) {
      tempTimeList = formatTimeSlots(selectedDate, timeList);
    }

    const triggerGetSubSpaceClusterWithSemiAutomationScheduleAvailabilityObject: ViewSubSpaceClustersWithScheduleAvailabilityRequestDTO =
      {
        spaceClusterId: locationState.spaceClusterId,
        subRootSpaceId: locationState.subRootSpaceId,
        date: moment(selectedDate).startOf("day").toISOString(true),
        repeatMode: selectedRepetition.value as ERepetitionMode,
      };

    if (selectedDeviceType.value) {
      triggerGetSubSpaceClusterWithSemiAutomationScheduleAvailabilityObject.category =
        selectedDeviceType.value;
    }

    if (
      (selectedRepetition.value as ERepetitionMode) !==
      ERepetitionMode.DO_NOT_REPEAT
    ) {
      triggerGetSubSpaceClusterWithSemiAutomationScheduleAvailabilityObject.expireDate =
        formatDate(moment(selectedEndDate).startOf("day").toDate());
    }

    if (locationState?.spaceClusterId && locationState?.subRootSpaceId) {
      (isSemiAutoMode
        ? triggerGetSubSpaceClusterWithSemiAutomationScheduleAvailability
        : triggerGetSubSpaceClusterWithManualScheduleAvailability)(
        triggerGetSubSpaceClusterWithSemiAutomationScheduleAvailabilityObject
      );
    }

    if (
      locationState?.spaceClusterId &&
      locationState?.subRootSpaceId &&
      tempTimeList.length > 0
    ) {
      const apiObject: ViewSemiAutomationScheduleDeviceAvailabilityRequestDTO =
        {
          scheduleAvailabilityType: EScheduleAvailabilityType.ALL,
          spaceClusterId: locationState.spaceClusterId,
          subRootSpaceId: locationState.subRootSpaceId,
          fromDate: tempTimeList[0].fromDate,
          toDate: tempTimeList[tempTimeList.length - 1].toDate,
          selectedPeriods: tempTimeList.map((time) => {
            return { fromDate: time.fromDate, toDate: time.toDate };
          }),
          repeatMode: selectedRepetition.value as ERepetitionMode,
        };

      if (selectedDeviceType.value) {
        apiObject.category = selectedDeviceType.value;
      }

      if (
        (selectedRepetition.value as ERepetitionMode) !==
        ERepetitionMode.DO_NOT_REPEAT
      ) {
        apiObject.expireDate = formatDate(
          moment(selectedEndDate).startOf("day").toDate()
        );
      }

      if (locationState?.scheduleId) {
        apiObject.excludeScheduleId = locationState.scheduleId;
      }

      (isSemiAutoMode
        ? triggerGetSemiAutomationScheduleAvailability
        : triggerGetManualScheduleAvailability)(apiObject)
        .unwrap()
        .then(
          (res: ViewSemiAutomationScheduleDeviceAvailabilityResponseDTO) => {
            setAvailabilityPowerConsumerList(
              Object.entries(res.powerConsumerScheduleAvailability).map(
                ([key, dateTimeRange]) => {
                  return {
                    deviceId: key,
                    dateTimeRange: dateTimeRange,
                  };
                }
              )
            );
            setPowerConsumerUsageGuide(res.powerConsumerTypeAvailabilityViews);
          }
        )
        .catch(() => {
          setAvailabilityPowerConsumerList([]);
          setPowerConsumerUsageGuide([]);
        });
    }
  }, [
    triggerGetSubSpaceClusterWithSemiAutomationScheduleAvailability,
    triggerGetSubSpaceClusterWithManualScheduleAvailability,
    triggerGetSemiAutomationScheduleAvailability,
    triggerGetManualScheduleAvailability,
    selectedDate,
    selectedEndDate,
    selectedRepetition,
    timeList,
    locationState?.spaceClusterId,
    locationState?.subRootSpaceId,
    selectedDeviceType.value,
  ]);

  const addIsOnPropertyToPowerConsumers = (
    space: IScheduledSpaceView,
    clusterId: string
  ): IScheduledSpaceViewWithDeviceStatus => {
    const foundSpace = findSpace(spaceClusters, space.id);

    if (space.childSpaces.length === 0) {
      const powerConsumers: IPowerConsumerViewWithSpaceIdAndClusterId[] =
        space.powerConsumers.map((powerConsumer) => {
          const foundDevice = foundSpace?.powerConsumers.find(
            (pc) => pc.id === powerConsumer.id
          );

          const tempDevice = availabilityPowerConsumerList.find((pc) => {
            return pc.deviceId === powerConsumer.id;
          });

          let tempDevice2;
          if (locationState.initialDeviceIds) {
            tempDevice2 = locationState.initialDeviceIds.find(
              (deviceId) => deviceId === powerConsumer.id
            );
          }

          const filteredPowerUsageGuide = powerConsumerUsageGuide?.filter(
            (usageGuide) =>
              Object.hasOwn(
                usageGuide.powerConsumerTypeAvailability,
                powerConsumer.deviceCategory
              )
          );

          if (filteredPowerUsageGuide?.length === 0) {
            return {
              ...powerConsumer,
              clusterId: clusterId,
              spaceId: space.id,
              isSelected: tempDevice2
                ? true
                : foundDevice
                ? foundDevice.isSelected
                : false,
              isScheduled: tempDevice ? true : false,
              isExcluded: false,
            };
          }

          const filteredExcludeFromSchedule = filteredPowerUsageGuide?.filter(
            (usageGuide) =>
              usageGuide.powerConsumerTypeAvailability[
                powerConsumer.deviceCategory
              ].filter((g) =>
                Object.hasOwn(g, ELimitationType.EXCLUDE_FROM_SCHEDULE)
              ).length !== 0
          );

          return {
            ...powerConsumer,
            clusterId: clusterId,
            spaceId: space.id,
            isSelected:
              (tempDevice2 || foundDevice?.isSelected) &&
              filteredExcludeFromSchedule?.length === 0
                ? true
                : false,
            isScheduled: tempDevice ? true : false,
            isExcluded: filteredExcludeFromSchedule?.length !== 0,
          };
        });

      let isScheduled = false;
      let isSelected = true;

      for (const powerConsumer of powerConsumers) {
        if (powerConsumer.isScheduled) {
          isScheduled = true;
        }
        if (
          isSemiAutoMode
            ? !powerConsumer.isSelected && !powerConsumer.isExcluded
            : !powerConsumer.isSelected
        ) {
          isSelected = false;
        }
      }

      return {
        ...space,
        powerConsumers: powerConsumers,
        clusterId: clusterId,
        isSelected: isSelected,
        isInitiallySelected: space.powerConsumers.length === 0,
        childSpaces: [],
        isScheduled: isScheduled,
      };
    } else {
      const powerConsumers: IPowerConsumerViewWithSpaceIdAndClusterId[] =
        space.powerConsumers.map((powerConsumer) => {
          const foundDevice = foundSpace?.powerConsumers.find(
            (pc) => pc.id === powerConsumer.id
          );

          const tempDevice = availabilityPowerConsumerList.find((pc) => {
            return pc.deviceId === powerConsumer.id;
          });

          let tempDevice2;
          if (locationState.initialDeviceIds) {
            tempDevice2 = locationState.initialDeviceIds.find(
              (deviceId) => deviceId === powerConsumer.id
            );
          }

          const filteredPowerUsageGuide = powerConsumerUsageGuide?.filter(
            (usageGuide) =>
              Object.hasOwn(
                usageGuide.powerConsumerTypeAvailability,
                powerConsumer.deviceCategory
              )
          );

          if (filteredPowerUsageGuide?.length === 0) {
            return {
              ...powerConsumer,
              clusterId: clusterId,
              spaceId: space.id,
              isSelected: tempDevice2
                ? true
                : foundDevice
                ? foundDevice.isSelected
                : false,
              isScheduled: tempDevice ? true : false,
              isExcluded: false,
            };
          }

          const filteredExcludeFromSchedule = filteredPowerUsageGuide?.filter(
            (usageGuide) =>
              usageGuide.powerConsumerTypeAvailability[
                powerConsumer.deviceCategory
              ].filter((g) =>
                Object.hasOwn(g, ELimitationType.EXCLUDE_FROM_SCHEDULE)
              ).length !== 0
          );

          return {
            ...powerConsumer,
            clusterId: clusterId,
            spaceId: space.id,
            isSelected:
              (tempDevice2 || foundDevice?.isSelected) &&
              filteredExcludeFromSchedule?.length === 0
                ? true
                : false,
            isScheduled: tempDevice ? true : false,
            isExcluded: filteredExcludeFromSchedule?.length !== 0,
          };
        });

      let isScheduled = false;
      let isSelected = true;

      for (const powerConsumer of powerConsumers) {
        if (powerConsumer.isScheduled) {
          isScheduled = true;
        }
        if (
          isSemiAutoMode
            ? !powerConsumer.isSelected && !powerConsumer.isExcluded
            : !powerConsumer.isSelected
        ) {
          isSelected = false;
        }
      }

      const childSpaces: IScheduledSpaceViewWithDeviceStatus[] = [];

      for (const currentSpaceItem of space.childSpaces) {
        childSpaces.push(
          addIsOnPropertyToPowerConsumers(currentSpaceItem, clusterId)
        );
      }

      let isAllChildSpacesActivated = true;
      let isChildSpacesScheduled = false;
      let isAllInitiallySelected = true;

      for (const cs of childSpaces) {
        if (!cs.isSelected) {
          isAllChildSpacesActivated = false;
        }
        if (cs.isScheduled) {
          isChildSpacesScheduled = true;
        }
        if (!cs.isInitiallySelected) {
          isAllInitiallySelected = false;
        }
      }

      return {
        ...space,
        clusterId: clusterId,
        powerConsumers: powerConsumers,
        isSelected:
          (space.powerConsumers.length === 0 || isSelected) &&
          isAllChildSpacesActivated,
        isInitiallySelected:
          space.powerConsumers.length === 0 && isAllInitiallySelected,
        childSpaces: childSpaces,
        isScheduled: isScheduled || isChildSpacesScheduled,
      };
    }
  };

  useEffect(() => {
    const tempArray: IScheduledSpaceViewWithDeviceStatus[] = [];
    const scheduleId = locationState?.scheduleId;

    if (!scheduleId || selectedDeviceType.value) {
      if (isSemiAutoMode) {
        if (
          isSuccessSubSpaceClusterWithSemiAutomationScheduleAvailability &&
          getDataSubSpaceClusterWithSemiAutomationScheduleAvailability
        ) {
          tempArray.push({
            ...addIsOnPropertyToPowerConsumers(
              getDataSubSpaceClusterWithSemiAutomationScheduleAvailability
                .spaceCluster.rootSpace,
              getDataSubSpaceClusterWithSemiAutomationScheduleAvailability
                .spaceCluster.id
            ),
          });

          let isAllDevicesActivated = true;

          for (const pc of tempArray[0].powerConsumers) {
            if (!pc.isSelected) {
              isAllDevicesActivated = false;
              break;
            }
          }

          let isAllChildSpacesActivated = true;

          if (isApplyForChildSpaces) {
            for (const cs of tempArray[0].childSpaces) {
              if (!cs.isSelected) {
                isAllChildSpacesActivated = false;
                break;
              }
            }
          }

          tempArray[0].isSelected =
            isAllDevicesActivated &&
            isAllChildSpacesActivated &&
            (isApplyForChildSpaces
              ? true
              : tempArray[0].powerConsumers.length > 0);

          setSpaceClusters(tempArray);
        }
      } else {
        if (
          isSuccessSubSpaceClusterWithManualScheduleAvailability &&
          getDataSubSpaceClusterWithManualScheduleAvailability
        ) {
          tempArray.push({
            ...addIsOnPropertyToPowerConsumers(
              getDataSubSpaceClusterWithManualScheduleAvailability.spaceCluster
                .rootSpace,
              getDataSubSpaceClusterWithManualScheduleAvailability.spaceCluster
                .id
            ),
          });

          let isAllDevicesActivated = true;

          for (const pc of tempArray[0].powerConsumers) {
            if (!pc.isSelected) {
              isAllDevicesActivated = false;
              break;
            }
          }

          let isAllChildSpacesActivated = true;

          if (isApplyForChildSpaces) {
            for (const cs of tempArray[0].childSpaces) {
              if (!cs.isSelected) {
                isAllChildSpacesActivated = false;
                break;
              }
            }
          }

          tempArray[0].isSelected =
            isAllDevicesActivated &&
            isAllChildSpacesActivated &&
            (isApplyForChildSpaces
              ? true
              : tempArray[0].powerConsumers.length > 0);

          setSpaceClusters(tempArray);
        }
      }
    } else {
      if (isSemiAutoMode) {
        if (
          scheduleId &&
          isSuccessViewSemiAutomatedScheduledSpaceCluster &&
          getDataSemiAutomatedScheduledSpaceCluster
        ) {
          tempArray.push({
            ...addIsOnPropertyToPowerConsumers(
              getDataSemiAutomatedScheduledSpaceCluster
                .semiAutomatedScheduleView.spaceCluster.rootSpace,
              getDataSemiAutomatedScheduledSpaceCluster
                .semiAutomatedScheduleView.spaceCluster.id
            ),
          });

          const isApplyForChildSpaces =
            getDataSemiAutomatedScheduledSpaceCluster.semiAutomatedScheduleView
              .isChildSpacePowerConsumersSelected;

          setIsApplyForChildSpaces(isApplyForChildSpaces);

          let isAllDevicesActivated = true;

          for (const pc of tempArray[0].powerConsumers) {
            if (!pc.isSelected) {
              isAllDevicesActivated = false;
              break;
            }
          }

          let isAllChildSpacesActivated = true;

          if (isApplyForChildSpaces) {
            for (const cs of tempArray[0].childSpaces) {
              if (!cs.isSelected) {
                isAllChildSpacesActivated = false;
                break;
              }
            }
          }

          tempArray[0].isSelected =
            isAllDevicesActivated &&
            isAllChildSpacesActivated &&
            (isApplyForChildSpaces
              ? true
              : tempArray[0].powerConsumers.length > 0);

          setSpaceClusters(tempArray);
        }
      } else {
        if (
          scheduleId &&
          isSuccessViewManualScheduledSpaceCluster &&
          getDataManualScheduledSpaceCluster
        ) {
          tempArray.push({
            ...addIsOnPropertyToPowerConsumers(
              getDataManualScheduledSpaceCluster.scheduleView.spaceCluster
                .rootSpace,
              getDataManualScheduledSpaceCluster.scheduleView.spaceCluster.id
            ),
          });

          const isApplyForChildSpaces =
            getDataManualScheduledSpaceCluster.scheduleView
              .isChildSpacePowerConsumersSelected;

          setIsApplyForChildSpaces(isApplyForChildSpaces);

          let isAllDevicesActivated = true;

          for (const pc of tempArray[0].powerConsumers) {
            if (!pc.isSelected) {
              isAllDevicesActivated = false;
              break;
            }
          }

          let isAllChildSpacesActivated = true;

          if (isApplyForChildSpaces) {
            for (const cs of tempArray[0].childSpaces) {
              if (!cs.isSelected) {
                isAllChildSpacesActivated = false;
                break;
              }
            }
          }

          tempArray[0].isSelected =
            isAllDevicesActivated &&
            isAllChildSpacesActivated &&
            (isApplyForChildSpaces
              ? true
              : tempArray[0].powerConsumers.length > 0);

          setSpaceClusters(tempArray);
        }
      }
    }
  }, [
    getDataSubSpaceClusterWithSemiAutomationScheduleAvailability,
    getDataSubSpaceClusterWithManualScheduleAvailability,
    isSuccessSubSpaceClusterWithSemiAutomationScheduleAvailability,
    isSuccessSubSpaceClusterWithManualScheduleAvailability,
    getDataSemiAutomatedScheduledSpaceCluster,
    isSuccessViewSemiAutomatedScheduledSpaceCluster,
    isSuccessViewManualScheduledSpaceCluster,
    getDataManualScheduledSpaceCluster,
    selectedDeviceType.value,
    availabilityPowerConsumerList.length,
  ]);

  useEffect(() => {
    const semiAutomationDeviceSelectAll =
      devicesCount.selected + devicesCount.excluded === devicesCount.total &&
      devicesCount.total !== 0;

    const manualDeviceSelectAll =
      devicesCount.selected === devicesCount.total && devicesCount.total !== 0;

    setSelectAll(
      isSemiAutoMode ? semiAutomationDeviceSelectAll : manualDeviceSelectAll
    );
  }, [devicesCount]);

  const updateSpaceStatus = (spaceClusterId: string, spaceId: string) => {
    const tempSpaceClusters: IScheduledSpaceViewWithDeviceStatus[] = JSON.parse(
      JSON.stringify(spaceClusters)
    );

    let space = tempSpaceClusters.find((cluster) => cluster.id === spaceId);

    let cluster: IScheduledSpaceViewWithDeviceStatus | undefined;

    if (!space) {
      cluster = tempSpaceClusters.find(
        (cluster) => cluster.clusterId === spaceClusterId
      );
    } else {
      let isAllDevicesActivated = true;

      for (const pc of space.powerConsumers) {
        if (
          isSemiAutoMode ? !pc.isSelected && !pc.isExcluded : !pc.isSelected
        ) {
          isAllDevicesActivated = false;
          break;
        }
      }

      let isAllChildSpacesActivated = true;

      for (const cs of space.childSpaces) {
        if (!cs.isSelected) {
          isAllChildSpacesActivated = false;
          break;
        }
      }

      space.isSelected = isAllDevicesActivated && isAllChildSpacesActivated;

      setSpaceClusters(tempSpaceClusters);
    }

    if (cluster) {
      space = findSpace(cluster.childSpaces, spaceId);

      if (space) {
        let isAllDevicesActivated = true;

        for (const pc of space.powerConsumers) {
          if (
            isSemiAutoMode ? !pc.isSelected && !pc.isExcluded : !pc.isSelected
          ) {
            isAllDevicesActivated = false;
            break;
          }
        }

        let isAllChildSpacesActivated = true;

        for (const cs of space.childSpaces) {
          if (!cs.isSelected) {
            isAllChildSpacesActivated = false;
            break;
          }
        }

        space.isSelected = isAllDevicesActivated && isAllChildSpacesActivated;

        setSpaceClusters(tempSpaceClusters);
      }
    }
  };

  const updateDeviceStatus = (
    spaceClusterId: string,
    spaceId: string,
    deviceId: string,
    deviceStatus: boolean
  ) => {
    const tempSpaceClusters: IScheduledSpaceViewWithDeviceStatus[] = JSON.parse(
      JSON.stringify(spaceClusters)
    );

    let space = tempSpaceClusters.find((cluster) => cluster.id === spaceId);

    let cluster: IScheduledSpaceViewWithDeviceStatus | undefined;

    if (!space) {
      cluster = tempSpaceClusters.find(
        (cluster) => cluster.clusterId === spaceClusterId
      );
    } else {
      const device = space.powerConsumers.find(
        (powerConsumer) => powerConsumer.id === deviceId
      );

      if (device) {
        device.isSelected = deviceStatus;
      }

      let isAllDevicesActivated = true;

      for (const pc of space.powerConsumers) {
        if (
          isSemiAutoMode ? !pc.isSelected && !pc.isExcluded : !pc.isSelected
        ) {
          isAllDevicesActivated = false;
          break;
        }
      }

      let isAllChildSpacesActivated = true;

      if (isApplyForChildSpaces) {
        for (const cs of space.childSpaces) {
          if (!cs.isSelected) {
            isAllChildSpacesActivated = false;
            break;
          }
        }
      }

      space.isSelected = isAllDevicesActivated && isAllChildSpacesActivated;

      setSpaceClusters(tempSpaceClusters);
    }

    if (cluster) {
      space = findSpace(cluster.childSpaces, spaceId);

      if (space) {
        const device = space.powerConsumers.find(
          (powerConsumer) => powerConsumer.id === deviceId
        );

        if (device) {
          device.isSelected = deviceStatus;
        }

        let isAllDevicesActivated = true;

        for (const pc of space.powerConsumers) {
          if (
            isSemiAutoMode ? !pc.isSelected && !pc.isExcluded : !pc.isSelected
          ) {
            isAllDevicesActivated = false;
            break;
          }
        }

        let isAllChildSpacesActivated = true;

        for (const cs of space.childSpaces) {
          if (!cs.isSelected) {
            isAllChildSpacesActivated = false;
            break;
          }
        }

        space.isSelected = isAllDevicesActivated && isAllChildSpacesActivated;

        setSpaceClusters(tempSpaceClusters);
      }
    }
  };

  const updateTopToBottomAllClusters = (status: boolean) => {
    const tempSpaceClusters = spaceClusters.map((cluster) => {
      return updateTopToBottomHelper(
        cluster,
        status,
        cluster.isInitiallySelected,
        powerConsumerUsageGuide,
        locationState?.managementMode
      );
    });

    setSpaceClusters(tempSpaceClusters);
  };

  const updateTopToBottom = (
    spaceClusterId: string,
    spaceId: string,
    spaceStatus: boolean
  ) => {
    const tempSpaceClusters: IScheduledSpaceViewWithDeviceStatus[] = JSON.parse(
      JSON.stringify(spaceClusters)
    );

    let space = tempSpaceClusters.find((cluster) => cluster.id === spaceId);

    let cluster: IScheduledSpaceViewWithDeviceStatus | undefined;

    if (!space) {
      cluster = tempSpaceClusters.find(
        (cluster) => cluster.clusterId === spaceClusterId
      );
    } else {
      const tempSpace = updateTopToBottomHelper(
        space,
        spaceStatus,
        space.isInitiallySelected,
        powerConsumerUsageGuide,
        locationState?.managementMode
      );

      space.isSelected = tempSpace.isSelected;
      space.childSpaces = [...tempSpace.childSpaces];
      space.powerConsumers = [...tempSpace.powerConsumers];

      setSpaceClusters(tempSpaceClusters);
    }

    if (cluster) {
      space = findSpace(cluster.childSpaces, spaceId);

      if (space) {
        const tempSpace = updateTopToBottomHelper(
          space,
          spaceStatus,
          space.isInitiallySelected,
          powerConsumerUsageGuide,
          locationState?.managementMode
        );

        space.isSelected = tempSpace.isSelected;
        space.childSpaces = [...tempSpace.childSpaces];
        space.powerConsumers = [...tempSpace.powerConsumers];

        setSpaceClusters(tempSpaceClusters);
      }
    }
  };

  useEffect(() => {
    triggerGetHierarchy({
      clusterId: locationState?.spaceClusterId,
      spaceId: locationState?.subRootSpaceId,
    })
      .unwrap()
      .then((response) => {
        let paths = "";
        response.ancestorSpaces.forEach((ancestor, index) => {
          paths +=
            index !== response.ancestorSpaces.length - 1
              ? `${ancestor.name} > `
              : `split${ancestor.name}`;
        });

        setBreadCrumbPath(paths);
      })
      .catch(() => {
        setBreadCrumbPath("");
      });
  }, [triggerGetHierarchy]);

  const currentTitle = watch("title", "");
  const currentDraft = watch("isDraft", false);

  return (
    <div className="container-white position-relative">
      <Row className="justify-content-between">
        <Col className="col-12 col-xl-5 pe-0">
          <Row>
            <Col>
              <Row>
                <Col>
                  <SpacePathView path={breadCrumbPath} />
                </Col>
                <Col className="col-auto">
                  <Tooltip
                    placement="top"
                    trigger={["hover"]}
                    overlayInnerStyle={{
                      borderColor: "#F8FA9C",
                      backgroundColor: "#F8F9EB",
                    }}
                    overlay={
                      <div className="text-light font-size-14 font-weight-400">
                        {isSemiAutoMode ? (
                          <>
                            Once a semi-auto schedule is added, devices will be
                            controlled <br /> automatically according to the
                            schedule
                          </>
                        ) : (
                          <>
                            Manual mode data is only used to create a schedule
                            of device usage, <br />
                            because devices in manual mode do not turn on
                            automatically
                          </>
                        )}
                      </div>
                    }
                  >
                    <div
                      className={`${styles.instruction} p-1 user-select-none`}
                    >
                      <MaterialIcon
                        icon="live_help"
                        color="#cbcd68"
                        size={20}
                      />
                    </div>
                  </Tooltip>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                  <ScheduleInformation
                    times={timeList}
                    setTimes={setTimeList}
                    errors={errors}
                    register={register}
                    isApplyForChildSpaces={isApplyForChildSpaces}
                    setIsApplyForChildSpaces={setIsApplyForChildSpaces}
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                    selectedEndDate={selectedEndDate}
                    setSelectedEndDate={(date: Date) => {
                      setSelectedEndDate(date);
                      if (
                        date <= selectedDate &&
                        !(
                          selectedRepetition.value ===
                          ERepetitionMode.DO_NOT_REPEAT
                        )
                      ) {
                        setEndDateError(
                          "Expire date should be later than start date"
                        );
                        return;
                      }
                      setEndDateError("");
                    }}
                    selectedRepetition={selectedRepetition}
                    setSelectedRepetition={setSelectedRepetition}
                    repetitionOptions={repetitionOptions}
                    isErrors={isTimeListHasErrors}
                    setIsErrors={setIsTimeListHasErrors}
                    isShowApplyForChildSpacesButton={
                      spaceClusters[0] &&
                      (spaceClusters[0].childSpaces.length !== 0 ||
                        spaceClusters[0].powerConsumers.length !== 0) &&
                      spaceClusters[0].childSpaces.filter(
                        (cs) => cs.isInitiallySelected
                      ).length !== spaceClusters[0].childSpaces.length
                    }
                    endDateError={endDateError}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              xs={1}
              className={`d-none d-xl-block ${
                timeList.length > 1 ? "ps-4" : ""
              }`}
            >
              <div className={styles["vertical-line"]}></div>
            </Col>
          </Row>
        </Col>
        <Col className="d-block d-xl-none my-5">
          <div className={styles["horizontal-line"]}></div>
        </Col>
        <Col className="col-12 col-xl-7">
          <Row>
            <Col lg={6}>
              <AppSelect
                label="Filter by Device Category"
                options={[...deviceTypes]}
                selectedValue={selectedDeviceType}
                onChangeOption={(selectedDeviceTypeOption) =>
                  setSelectedDeviceType(selectedDeviceTypeOption)
                }
                menuHeight={"250px"}
              />
            </Col>
          </Row>
          <Row className="align-items-center justify-content-between mt-4">
            <Col className="text-light font-weight-400 font-size-14">
              <Row>
                <Col>{`${devicesCount.selected} of ${devicesCount.total}`}</Col>
              </Row>
              <Row>
                <Col>Devices Selected</Col>
              </Row>
            </Col>
            <Col className="col-auto">
              <Row className="align-items-center">
                <Col className="text-light font-weight-400 font-size-14">
                  Select All
                </Col>
                <Col xs={"auto"}>
                  <AppSwitch
                    isOn={selectAll}
                    size="sm"
                    onSwitch={(value: boolean) => {
                      updateTopToBottomAllClusters(value);
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className={`mt-4 ${styles.overflow} align-items-start`}>
            <Col>
              {spaceClusters.length > 0 ? (
                spaceClusters.map((space, index) => {
                  return (space.childSpaces.length !== 0 ||
                    space.powerConsumers.length !== 0) &&
                    devicesCount.total > 0 &&
                    !space.isInitiallySelected ? (
                    <SpaceHierarchy
                      key={index}
                      mainSpace={{
                        ...space,
                        clusterId: space.clusterId,
                      }}
                      expandable={true}
                      collapsed={collapsed && !isFirstTime}
                      isExpanded={!collapsed}
                      indent={false}
                      updateDeviceStatus={updateDeviceStatus}
                      updateSpaceStatus={updateSpaceStatus}
                      updateTopToBottom={updateTopToBottom}
                      isApplyForChildSpaces={isApplyForChildSpaces}
                      powerConsumerUsageGuide={powerConsumerUsageGuide}
                      conflictedScheduleList={availabilityPowerConsumerList}
                      selectedManagementMode={locationState?.managementMode}
                    />
                  ) : (
                    index === 0 && (
                      <div className="container-dash mt-4" key={index}>
                        <Col className="text-center text-light font-size-12">
                          {isSemiAutoMode
                            ? `There are no devices associated with controllers for
                          the selected space or selected category`
                            : `There are no devices for
                          the selected space or selected category`}
                        </Col>
                      </div>
                    )
                  );
                })
              ) : (
                <div className="container-dash mt-4">
                  <Col className="text-center text-light font-size-12">
                    {isSemiAutoMode
                      ? `There are no devices associated with controllers for the
                    selected space or selected category`
                      : `There are no devices for the
                    selected space or selected category`}
                  </Col>
                </div>
              )}
            </Col>
            {isApplyForChildSpaces && (
              <Col
                xs="auto"
                className="font-size-14 font-weight-400 text-primary cursor-pointer text-underline"
                onClick={() => {
                  setCollapsed((ps) => !ps);
                  setIsFirstTime(false);
                }}
              >
                {collapsed ? "Expand All" : "Collapse All"}
              </Col>
            )}
          </Row>
        </Col>
      </Row>
      <Row
        className="justify-content-center justify-content-sm-between mt-5 px-2"
        style={{
          paddingTop: `${
            selectedRepetition.value !== ERepetitionMode.DO_NOT_REPEAT &&
            timeList.length * 50
          }px`,
        }}
      >
        <Col className="col-auto">
          <AppButton
            onClick={() => navigate(AppRoute.SCHEDULE)}
            text="Cancel"
            variant="transparentWithBorder"
            size="medium"
          />
        </Col>
        <Col className={`${styles["cancel-button-margin-top"]} col-auto`}>
          <Row>
            {(!locationState?.scheduleId || currentDraft) && (
              <Col xs="auto" className="p-0">
                <AppButton
                  text="Save as Draft"
                  isLoading={isOnDraftLoading && isDraft && !isAutomate}
                  loaderColor="#2f2a89"
                  variant="transparent"
                  size="medium"
                  className="px-1"
                  onClick={handleSubmit(onDraftSubmit)}
                  id="schedule-draft"
                />
              </Col>
            )}
            <Col xs="auto">
              <AppButton
                onClick={handleSubmit(onAutomateSubmit)}
                isLoading={isOnAutomateLoading && !isDraft && isAutomate}
                text="Automate"
                variant="blue"
                size="medium"
                id="schedule-automate"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {locationState?.scheduleId && (
        <ScheduleDeleteModal
          show={showDeleteModal}
          onCancel={() => setShowDeleteModal(false)}
          onClose={() => setShowDeleteModal(false)}
          spaceClusterId={locationState.spaceClusterId}
          subRootSpaceId={locationState.subRootSpaceId}
          deleteScheduleData={{
            scheduleId: locationState.scheduleId,
            scheduleTitle: currentTitle,
          }}
          selectedManagementMode={locationState?.managementMode}
        />
      )}
      <ScheduleConfirmationModal
        show={(isDraft || isAutomate) && alreadyScheduleDevices.length > 0}
        path={breadCrumbPath}
        isLoading={isOnDraftLoading || isOnAutomateLoading}
        onConfirm={async () => {
          if (isDraft) {
            setIsOnDraftLoading(true);
            await onSubmit(formData, true);
            setIsOnDraftLoading(false);
            setIsDraft(false);
            setIsAutomate(false);
            return;
          }

          setIsOnAutomateLoading(true);
          await onSubmit(formData, false);
          setIsOnAutomateLoading(false);
          setIsDraft(false);
          setIsAutomate(false);
        }}
        onCancel={() => {
          setIsDraft(false);
          setIsAutomate(false);
        }}
        onClose={() => {
          setIsDraft(false);
          setIsAutomate(false);
        }}
        devices={alreadyScheduleDevices}
      />
      <SpinnerModal
        show={
          isFetchingSubSpaceClusterWithSemiAutomationScheduleAvailability ||
          isFetchingSubSpaceClusterWithManualScheduleAvailability ||
          isFetchingViewSemiAutomatedScheduledSpaceCluster ||
          isFetchingViewManualScheduledSpaceCluster ||
          isFetchingSemiAutomationScheduleAvailability ||
          isFetchingManualScheduleAvailability
        }
      />
    </div>
  );
};

export default AddUpdateSchedule;
