import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { EDeviceStatus } from "../../../shared/oversight-core/enums/device-status";
import { ETimeGridYAxisType } from "../../../shared/oversight-core/enums/time-grid-y-axis-type";
import { ISpaceView } from "../../../shared/oversight-core/interfaces/entities/space";
import IScheduleFilters from "../../../shared/oversight-core/interfaces/schedule-filters";
import { RootState } from "../../store";

const defaultSelectedSpace: ISpaceView = {
  id: "",
  name: "",
  parentSpaceId: "",
  powerConsumers: [],
  childSpaces: [],
  clusterId: "",
  tariffCode: "",
  accountNumber: "",
  accountNumberLabel: "",
  smartDevices: [],
  powerState: EDeviceStatus.OFF,
};

const defaultBillCalculatorFilters: Omit<IScheduleFilters, "managementMode"> = {
  selectedSpace: { ...defaultSelectedSpace },
  selectedDate: moment().valueOf(),
  selectedSpaceDetails: { spaceClusterId: "", spaceId: "" },
  badgeCount: 0,
  timeGridYAxis: ETimeGridYAxisType.DATE,
};

interface IScheduleFiltersSpaceAndSpaceDetails {
  selectedSpace: ISpaceView;
  selectedSpaceDetails: { spaceClusterId: string; spaceId: string };
}

export const billCalculatorFilterSlice = createSlice({
  name: "billCalculatorFilter",
  initialState: { ...defaultBillCalculatorFilters },
  reducers: {
    setBillCalculatorFilter: (
      state,
      action: PayloadAction<Omit<IScheduleFilters, "managementMode">>
    ) => {
      state.selectedSpace = action.payload.selectedSpace;
      state.selectedDate = action.payload.selectedDate;
      state.selectedSpaceDetails = action.payload.selectedSpaceDetails;
      state.badgeCount = action.payload.badgeCount;
      state.timeGridYAxis = action.payload.timeGridYAxis;
    },
    setBillCalculatorFiltersSpaceAndSpaceDetails: (
      state,
      action: PayloadAction<IScheduleFiltersSpaceAndSpaceDetails>
    ) => {
      state.selectedSpace = action.payload.selectedSpace;
      state.selectedSpaceDetails = action.payload.selectedSpaceDetails;
    },
  },
});

export const {
  setBillCalculatorFilter,
  setBillCalculatorFiltersSpaceAndSpaceDetails,
} = billCalculatorFilterSlice.actions;

export const selectBillCalculatorFilter = (state: RootState) =>
  state.billCalculatorFilter;

export default billCalculatorFilterSlice.reducer;
