import { Col, Row } from "react-bootstrap";
import AppStore from "./assets/app-store.png";
import GooglePlay from "./assets/google-play.png";
import styles from "./registration-through-app.module.scss";

const RegistrationThroughApp = () => {
  return (
    <>
      <Row className="justify-content-center">
        <Col className={`${styles.registrationThrough}`}>
          Don’t have an account? Register via my LECO App
        </Col>
      </Row>
      <Row className="align-items-center g-2 mt-2 justify-content-center">
        <Col className="text-end">
          <img
            className={`${styles.appImage} img-fluid`}
            src={GooglePlay}
            alt="google play icon"
          />
        </Col>
        <Col>
          <img
            className={`${styles.appImage} img-fluid`}
            src={AppStore}
            alt="app store icon"
          />
        </Col>
      </Row>
    </>
  );
};

export default RegistrationThroughApp;
