import moment from "moment";
import { Col, Row } from "react-bootstrap";
import { IEnergyView } from "../../../../../shared/oversight-core/interfaces/entities/energy-view";
import { IScheduledSpaceClusterAverageWeeklyEnergy } from "../../../../../shared/oversight-core/interfaces/scheduled-space-cluster-average-weekly-energy";
import AppDatePicker from "../../../../../shared/oversight-core/ui-elements/app-date-picker/app-date-picker";
import AppSelect, {
  Option,
} from "../../../../../shared/oversight-core/ui-elements/app-select/app-select";
import AverageUsageUnits from "../../../../../shared/oversight-core/ui-elements/average-usage-units/average-usage-units";
import AppButton from "../../../../../shared/oversight-core/ui-elements/buttons/app-button/app-button";
import StatsViewTypeTwo from "../../../../../shared/oversight-core/ui-elements/stats-view/stats-view-type-two";
import Helper from "../../../../../shared/oversight-core/utils/helpers";

interface IProps {
  selectedDate: Date;
  isFetching: boolean;
  scheduledSpaceClusterAverageWeeklyEnergy: IScheduledSpaceClusterAverageWeeklyEnergy;
  spaceClusterEstimatedConsumption: IEnergyView;
  selectedTariff: Option;
  tariffs: Option[];
  setSelectedTariff: React.Dispatch<React.SetStateAction<Option>>;
  setSelectedDate: React.Dispatch<React.SetStateAction<Date>>;
  onSaveToSchedule: () => void;
}

const BillCalculatorTabView = (props: IProps) => {
  const {
    selectedDate,
    isFetching,
    scheduledSpaceClusterAverageWeeklyEnergy,
    spaceClusterEstimatedConsumption,
    selectedTariff,
    tariffs,
    setSelectedTariff,
    setSelectedDate,
    onSaveToSchedule,
  } = props;

  return (
    <Row className={`align-items-start justify-content-center`}>
      <Col className="col-12 col-xxl">
        <Row className="align-items-center">
          <Col className="col-12 col-sm-6 col-xl">
            <AppButton text="Save to Schedule" onClick={onSaveToSchedule} />
          </Col>
          <Col className="col-12 col-sm-6 col-xl-auto mt-2 mt-sm-0">
            <AppButton text="Clear" variant="transparent" />
          </Col>
          <Col className="col-12 col-sm-6 col-xl mt-2 mt-xl-0">
            <AppSelect
              selectedValue={selectedTariff}
              options={[...tariffs]}
              onChangeOption={(selectOption) => {
                setSelectedTariff(selectOption);
              }}
              fontSize="12px"
              labelFontSize="font-size-14"
              fontWeight="500"
              fontColor="#69768b"
            />
          </Col>
          <Col className="col-12 col-sm-6 col-xl mt-2 mt-xl-0">
            <AppDatePicker
              monthYearPicker
              onChange={(date) => {
                setSelectedDate(moment(date).startOf("month").toDate());
              }}
              selectedDate={selectedDate}
              fontSize={12}
              datePickerTextClassName="text-app-select font-weight-400"
            />
          </Col>
        </Row>
      </Col>
      <Col xs={12} sm={12}>
        <Row className="align-items-center justify-content-center mt-3">
          <Col md={12} lg={"auto"} className="px-4">
            <AverageUsageUnits
              variant="bg-icon-2"
              title="Average Usage Units"
              labelValue={{
                weekDay:
                  Helper.roundTo2(
                    scheduledSpaceClusterAverageWeeklyEnergy?.weekdayDailyEnergy
                      .energyInUnits
                  ) + "",
                weekEnd:
                  Helper.roundTo2(
                    scheduledSpaceClusterAverageWeeklyEnergy?.weekendDailyEnergy
                      .energyInUnits
                  ) + "",
                weekly:
                  Helper.roundTo2(
                    scheduledSpaceClusterAverageWeeklyEnergy?.weeklyEnergy
                      .energyInUnits
                  ) + "",
              }}
              titleStyle="jakarta font-weight-500 text-dark"
              isFetching={isFetching}
            />
          </Col>
          <Col sm={6} lg="auto" className="mt-3 mt-lg-0">
            <StatsViewTypeTwo
              isFetching={isFetching}
              variant="bg-icon-2"
              labelValue={
                Helper.roundTo2(
                  spaceClusterEstimatedConsumption.energyInUnits
                ) + ""
              }
              title="Estimated Units"
            />
          </Col>
          <Col sm={6} lg="auto" className="mt-3 mt-lg-0">
            <StatsViewTypeTwo
              isFetching={isFetching}
              variant="bg-icon-2"
              labelValue={
                "Rs." +
                Helper.roundTo2(spaceClusterEstimatedConsumption.energyBill) +
                ""
              }
              title="Estimated Bill"
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default BillCalculatorTabView;
